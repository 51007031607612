<template>
<div class="container " v-loading="loading" style="box-sizing:border-box;" :class="{'print':print}">
    <div class="schedule-header" v-if="!print">
        <el-row class="info">
            <div class="left">
                <el-col :lg='6' :sm="11">
                    <span class="label-2">名称：</span>
                    <span v-if="editLock">{{editForm.name}}</span>
                    <el-input v-else v-model="editForm.name"
                        class="circular-bead-input info-input" style="" placeholder="请输入计划表名称">
                    </el-input>
                </el-col>
                <el-col :lg='5' :sm="11">
                    <span class="label-2">周次：</span>
                    <span v-if="editLock">{{editForm.week}}</span>
                    <el-select v-else v-model="editForm.week" placeholder="请选择周次"
                        class="circular-bead-select info-input" popper-class="weekListSelect">
                        <el-option v-for="item in weekNumList" :key="item.value"
                            :label="item.label" :value="item.value" :disabled="item.disabled">
                            <el-tooltip :disabled="!item.disabled" effect="dark" content="已创建计划" placement="top">
                                <span>{{ item.value }}</span>
                            </el-tooltip>
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :lg='12' :sm="23">
                    <span class="label-4">起止时间：</span>
                    <span v-if="editLock">{{editForm.beginTime | timeFilter(3)}} - {{editForm.endTime | timeFilter(3)}}</span>
                    <div class="date-picker-group info-input" v-else>
                        <el-date-picker class="circular-bead-date-picker" v-model="editForm.beginTime" type="date"
                            value-format="timestamp" placeholder="选择开始日期" @blur='checkTime("beginTime")'>
                        </el-date-picker>
                        <div class="split-line">-</div>
                        <el-date-picker class="circular-bead-date-picker" v-model="editForm.endTime" type="date"
                            value-format="timestamp" placeholder="选择结束日期" @blur='checkTime("endTime")'>
                        </el-date-picker>
                    </div>
                </el-col>
            </div>
            <div class="right">
                <template v-if="userType !== USER_TYPE.ORG_SCHOOL">
                    <el-col v-if="canEdit && editLock && auth('week.plan.update')">
                        <el-button round @click="routerChange(1)">
                            <i class="iconfont icon-edit" style="position: relative;top: 1px;"/> 编辑
                        </el-button>
                    </el-col>
                    <el-col v-if="!editLock && canEdit || !editForm.id" class="edit-set-btn">
                        <el-button round  @click="handleResetSchedule">重置</el-button>
                        <el-button round @click="hadnleCancel">取消</el-button>
                        <el-button round type="primary" @click="handleSave">保存</el-button>
                    </el-col>
                </template>
            </div>
        </el-row>
    </div>
    <div v-else class="schedule-print-header">
        <div class="logo">
            <!-- <img :src="logoImg" alt="" /> -->
        </div>
        <div class="info">
            <p class="title">{{editForm.name}}</p>
            <p class="sub">
                <!-- <span>第 {{editForm.week}} 周工作计划表</span> -->
                <span>{{editForm.beginTime | timeFilter(4)}} - {{editForm.endTime | timeFilter(4)}}</span>
            </p>
        </div>
    </div>

    <div class="schedule-body" v-if="!print">
        <div class="schedule-title">
            <div style="width:240px;">课程</div>
            <div class="schedule-title-item" v-for="(item, index) of scheduleTitleList" :key="index">
                {{ item }}
            </div>
            <el-button @click="handleChangeWeekDayNum" type="text"
                class="schedule-title-change" v-if="!editLock">
                <div class="tooltip">
                    <p>{{scheduleTitleList.length === 5 ? "添加周末列表" : "取消周末列表"}}</p>
                </div>
                <i class="el-icon-plus" v-if="scheduleTitleList.length === 5"></i>
                <i class="el-icon-minus" v-else></i>
            </el-button>
        </div>
        <draggable :list="scheduleData" v-bind="dragOptions" class="list-group" handle=".handle"
            @start="dragStart" @end="dragEnd">
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                <div v-for="(item, index) of scheduleData" :key="item.id + item.name">
                    <schedule-item class="schedule-item" :item="item" v-if="hackReset === true" :item-index="index"
                        :resource-list="resourceList" :res-total="resTotal" :current-res-page="currentResPage" :current-res-name='currentResName'
                        :week-len="scheduleTitleList.length" :showStyle="showStyle"  :editLock="editLock"
                        @handleSearchRes="getResourceList"
                        @handleDeleteSelf="handleDeleteSelf"
                        @handleResDirectory="handleResDirector">
                    </schedule-item>
                </div>
            </transition-group>
        </draggable>
        <div class="schedule-item" v-if="!editLock" >
            <div class="list-group-item add">
                <div class="title" @click="addTitleItem">
                    <i class="el-icon-plus"></i>
                </div>
                <div class="item" v-for="(day, index) of scheduleTitleList" :key="index">
                </div>
            </div>
        </div>
    </div>
    <div class="schedule-print-body" v-else>
        <div class="schedule-title">
            <div class="title">课程</div>
            <div class="schedule-title-item" v-for="(item, index) of scheduleTitleList" :key="index">
                {{ item }}
            </div>
        </div>
        <div class="list-group">
            <div v-for="(item, index) of scheduleData" :key="index + item.name">
                <schedule-item class="schedule-item" :item="item" v-if="hackReset === true" :itemIndex="index"
                    :resource-list="resourceList" :res-total="resTotal" :current-res-page="currentResPage"
                    :week-len="scheduleTitleList.length" :show-style="showStyle" :print='print' :edit-lock="editLock"
                    @handleSearchRes="getResourceList"
                    @handleDeleteSelf="handleDeleteSelf"
                    @handleResDirectory="handleResDirector">
                </schedule-item>
            </div>
        </div>
    </div>

    <div class="schedule-footer" :class="{'schedule-print-footer':print}">
        <div class="content">
            <div class="item left">
                <div class="title">工作重点</div>
                <div class="text-area" v-if="editLock">{{editForm.focus}}</div>
                <el-input v-else class="input-area" style="white-space: pre-wrap;" type="textarea"
                    :autosize="{ minRows: 6, maxRows: 8}" placeholder="请输入内容" v-model="editForm.focus"
                    :show-word-limit="true">
                </el-input>
            </div>
            <div class="item">
                <div class="title">家长工作</div>
                <div class="text-area" v-if="editLock">{{editForm.parent_work}}</div>
                <el-input v-else class="input-area" style="white-space: pre-wrap;" type="textarea"
                    :autosize="{ minRows: 6, maxRows: 8}" placeholder="请输入内容" v-model="editForm.parent_work"
                    :show-word-limit="true">
                </el-input>
            </div>
        </div>
        <div class="powered" v-if="print">
            <!-- <img :src="poweredImg" alt="" /> -->
        </div>
    </div>
</div>
</template>

<script>
import Core from '@/core';
import _ from '@kaobeimao/core';

import { mapState, mapMutations } from 'vuex';
import * as types from '@/store/mutation-types';

import draggable from 'vuedraggable';

import dayjs from 'dayjs';
const weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);

const FULL_WEEK = ['周一', '周二', '周三', '周四', '周五', '周六', '周日'];
const NORMAL_WEEK = ['周一', '周二', '周三', '周四', '周五'];

export default {
    components: {
        draggable,
        ScheduleItem: () => import('./ScheduleItem')
    },
    props: {
        showStyle: {
            default: 1
        },
        print: {
            type: Boolean,
            default: false,
        },
        planYear: {},
    },
    data() {
        return {
            userType: Core.Data.getUserType(),
            USER_TYPE: Core.Const.USER_TYPE,

            logoImg: require('@/assets/images/logo-print.png'),
            poweredImg: require('@/assets/images/powered.png'),
            planId: 0,
            editLock: false,
            scheduleTitleList: ['周一', '周二', '周三', '周四', '周五'],
            weekLen: 5,
            drag: false,
            editForm: {
                id: '',
                name: '',
                year: '',
                week: '',
                date: null,
                beginTime: '',
                endTime: '',
                focus: '',
                parent_work: ''
            },

            currentResPage: 1,
            currentResName: '',
            resTotal: 1,
            resourceList: [],

            loading: false,
            hackReset: true,
            isNewPlan: false,
            canEdit: 0,
            isSaved: false,
            weekNumList: [],
            ScheduleWeekNum: [],
            dirId: '',
        };
    },
    computed: {
        ...mapState(['scheduleData']),
        dragOptions() {
            return {
                animation: 200, // 动画时间
                disabled: false, // false可拖拽，true不可拖拽
                group: 'description',
                ghostClass: 'ghost'
            };
        }
    },
    created() {
        console.log('scheduleData', this.scheduleData);
        this.planId = this.$route.query.plan_id;
        if (this.$route.query.edit_lock) { // 禁止编辑
            this.editLock = true;
        }
        if (this.$route.query.new_plan) {
            this.editForm.year =  this.$route.query.year;
            this.editForm.week = '';
            this.isNewPlan = true;
        } else {
            this.editForm.year = this.$route.query.year || dayjs().year();
            this.editForm.week = this.$route.query.week || dayjs().week();
        }
        this.init();
        if (!this.print) {
            this.getResourceList();
            this.getWeekList();
        }
        this.canEdit = Number(this.$route.query.can_edit) || 0
    },
    methods: {
        auth: Core.Utils.auth,
        ...mapMutations({
            setScheduleData: types.SET_SCHEDULE_DATA,
            setAddScheduleData: types.SET_ADD_SCHEDULE_DATA_ITEM,
            setDeleteScheduleData: types.SET_DELETE_SCHEDULE_DATA_ITEM,
            setReplaceScheduleData: types.SET_REPLACE_SCHEDULE_DATA,
            setResetScheduleData: types.SET_RETSET_SCHEDULE_DATA
        }),
        async init() {
            try {
                this.loading = true;
                if (!this.editForm.week) {
                    this.resetSchedule();
                    return;
                }
                const { course } = await Core.Api.WeekPlan.detail(
                    this.editForm.year,
                    this.editForm.week,
                    this.planId
                );
                // console.log('course',course)
                // console.log('course.config',JSON.parse(course.config))
                if (course) {
                    this.editForm.id = course.id;
                    this.editForm.name = course.name;
                    this.editForm.year = course.year;
                    this.editForm.week = course.week;
                    this.editForm.beginTime = course.begin_time * 1000;
                    this.editForm.endTime = course.end_time * 1000;
                    const config = JSON.parse(course.config);
                    this.editForm.focus = config.focus; // 工作重点
                    this.editForm.parent_work = config.parent_work; // 家长工作
                    this.hackReset = false;
                    this.$nextTick(() => {
                        this.setReplaceScheduleData(config.schedule);
                        this.scheduleTitleList = this.getScheduleTitleEnableList();
                        this.hackReset = true;
                    });
                } else {
                    this.hadnleResetSchedule();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        getScheduleTitleEnableList() { // 获取表格的标题
            let scheduleTitleList;
            if (this.scheduleData[0].week.length === 5) {
                scheduleTitleList = NORMAL_WEEK;
            } else {
                scheduleTitleList = FULL_WEEK;
            }
            return scheduleTitleList;
        },
        handleChangeWeekDayNum() { // 切换是否开放周末
            if (this.scheduleTitleList.length === 5) {
                this.scheduleTitleList = FULL_WEEK;
            } else {
                this.scheduleTitleList = NORMAL_WEEK;
            }
            let schedule = JSON.parse(JSON.stringify(this.scheduleData));
            schedule.forEach((item) => {
                if (item.week.length === 5) {
                    item.week = [...item.week, { key: '' }, { key: '' }];
                } else if (item.week.length === 7) {
                    item.week.splice(5, 2);
                }
            });
            this.hackReset = false;
            this.$nextTick(() => {
                this.setReplaceScheduleData(schedule);
                this.hackReset = true;
            });
        },
        handleResetSchedule() { //重置表格
            this.$confirm('确定要重置表格吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.resetSchedule();
            })
        },
        resetSchedule() {
            this.editForm.name = '';
            this.editForm.date = null;
            this.editForm.beginTime = '';
            this.editForm.endTime = '';
            this.editForm.focus = '';
            this.editForm.parent_work = '';
            this.hackReset = false;
            this.$nextTick(() => {
                this.setResetScheduleData();
                this.scheduleTitleList = this.getScheduleTitleEnableList();
                this.hackReset = true;
            });
        },
        getResourceList(data = {}) { // 获取资源库资源
            console.log('getResourceList data:', data)
            const name = data.name || ''
            const page = data.page || 1
            this.currentResPage = page
            this.currentResName = name
            Core.Api.Resource.list(
                page,
                8,
                name,
                '',
                '',
                '',
                this.dirId,
                1,
            ).then((res) => {
                this.resTotal = res.count;
                for (let i = 0; i < res.list.length; i++) {
                    const files = JSON.parse(res.list[i].files);
                    res.list[i].files_type = files.map((item) => Core.Utils.fileTypeFilter(item.name));
                }
                // console.log("getResourceList -> res.list", res.list)
                this.resourceList = res.list;
            });
        },
        handleResDirector(dirId) {
            this.dirId = dirId
            this.getResourceList()
        },
        dragStart() {
            this.drag = true;
        },
        dragEnd() {
            this.drag = false;
        },
        removeAt(idx) {  // ??
            this.scheduleData.splice(idx, 1);
        },
        handleDeleteSelf(index) { // 删除一行
            this.setDeleteScheduleData({ index });
        },
        addTitleItem() { // 增加一行
            this.$prompt('请输入新增日程名称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(({ value }) => {
                this.setAddScheduleData({
                    name: value,
                    length: this.scheduleTitleList.length,
                    beginTime: 0,
                    endTime: 0,
                });
            });
        },
        routerChange(key, q = '') { // 路由跳转
            switch (key) {
                case 1:
                    this.editLock = false;
                    break;
                case 2:
                    this.$router.go(-1);
                    break;
                case 3:
                    if (this.isNewPlan) {
                        this.$router.go(-1);
                    } else {
                        this.editLock = true;
                    }
                    break;
            }
        },
        async handleSave() {  // 保存
            if (
                _.U.isEmpty(_.U.toString(this.editForm.beginTime)) ||
                _.U.isEmpty(_.U.toString(this.editForm.endTime))
            ) {
                this.$message.warning('请选择时间范围');
                return;
            }
            if (_.U.isEmpty(this.editForm.name)) {
                this.$message.warning('请填写计划表名称');
                return;
            }
            const config = {
                schedule: this.scheduleData,
                focus: this.editForm.focus,
                parent_work: this.editForm.parent_work
            };
            const { course } = await Core.Api.WeekPlan.detail(
                this.editForm.year,
                this.editForm.week,
                this.planId
            );
            if (course && this.editForm.id !== course.id ) {
                this.$confirm('当前周次已有模版，是否覆盖?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    Core.Api.WeekPlan.save(
                        this.editForm.id,
                        this.editForm.week,
                        this.planYear,
                        this.editForm.name,
                        this.editForm.beginTime / 1000,
                        dayjs(this.editForm.endTime).endOf('date').unix(),
                        JSON.stringify(config),
                        this.planId,
                    ).then((res) => {
                        this.$message.success('添加成功');
                        this.isSaved = true
                        this.$router.go(-1);
                    });
                })
            } else {
                Core.Api.WeekPlan.save(
                    this.editForm.id,
                    this.editForm.week,
                    this.planYear,
                    this.editForm.name,
                    this.editForm.beginTime / 1000,
                    dayjs(this.editForm.endTime).endOf('date').unix(),
                    JSON.stringify(config),
                    this.planId
                ).then((res) => {
                    this.$message.success('添加成功');
                    this.isSaved = true
                    this.$router.go(-1);
                });
            }
        },
        hadnleCancel(){ // 取消
            this.$confirm('确定要取消本次更改吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.isSaved = true
                this.$router.go(-1);
                this.init()
            })
        },
        getWeekList(){ // 周计划 周 的选择
            Core.Api.WeekPlan.weekList( // 获取所有计划
                this.planId,
                this.editForm.year,
            ).then((res) => {
                this.ScheduleWeekNum = res.list
                for (let i = 0; i < 52; i++) {
                    let num = i + 1;
                    let disabled = false;
                    if (this.ScheduleWeekNum.includes(num)) {
                        disabled = true;
                        if (num === this.editForm.week) {
                            disabled = false;
                        }
                    }
                    this.weekNumList.push({
                        value: num,
                        label: '第' + num + '周',
                        disabled,
                    })
                }
            });
        },
        checkTime(type){  // 检查时间合理性
            if (this.editForm.endTime && this.editForm.beginTime) {
                if (this.editForm.endTime < this.editForm.beginTime) {
                    this.$message.warning('请选择合理的时间范围');
                    this.editForm[type] = '';
                }
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.container {
    width: 100%;
    text-align: center;
    @include no-select;
    .schedule-header {
        margin-bottom: 24px;
        .info{
            @include flex(row, space-between, center);
            padding: 10px;
            background-color: #fff;
            $Label2Width: 42px;
            $Label4Width: 80px;
            .left {
                width: calc(100% - 200px);
                @include flex(row, space-between, center);
                display: inline-flex;
                flex-wrap: wrap;
                .el-col{
                    @include flex(row, flex-start, center);
                    padding: 2px 0;
                }
                .label{
                    &-2{
                        text-align: left;
                        width: $Label2Width;
                    }
                    &-4{
                        width: $Label4Width;
                        text-align: left;
                    }
                }
                .info-input{
                    width: calc(100% - 60px);
                }
                .date-picker-group{
                    .el-input{
                        width: 50%;
                    }
                }
            }
            .right{
                width: 200px;
                padding: 2px 0;
                @include flex(row, flex-end, center);
                display: inline-flex;
                flex-wrap: wrap;
                .edit-set-btn{
                    @include flex(row, center, center);
                    flex-wrap: wrap;
                }
                .el-button{
                    margin: 4px;
                }
                @media only screen and (min-width: 992px) and (max-width: 1200px) {
                }
            }
        }
        .title {
            @include flex(row, flex-start, center);
            padding: 15px 30px;
            text-align: left;
            font-size: 20px;
            line-height: 32px;
            font-weight: 500;
            color: rgba(50, 53, 56, 1);
            @include flex(row, flex-start, center);
            .el-icon-back {
                font-size: 24px;
                color: #1D517E;
                font-weight: 800;
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }
    .schedule-body, .schedule-print-body {
        padding: 0 30px;
        .schedule-title {
            display: flex;
            height: 44px;
            line-height: 44px;
            border-radius: 6px 6px 0px 0px;
            background: linear-gradient(180deg,rgba(251, 252, 253, 1) 0%,rgba(246, 248, 250, 1) 100%);
            border: 1px solid rgba(220, 227, 232, 1);
            position: relative;
            .schedule-title-item {
                flex: 1;
                box-sizing: border-box;
                border-left: 1px solid rgba(220, 227, 232, 1);
            }
            .schedule-title-change{
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                .tooltip{
                    opacity: 0;
                    transition: opacity .3s ease-in;
                    position: absolute;
                    top: -48px;
                    right: -4px;
                    width:116px;
                    height:44px;
                    background:rgba(255,255,255,1);
                    box-shadow:0px 2px 8px 0px rgba(0,0,0,0.15);
                    border-radius: 6px;
                    &::after{
                        content: '';
                        position: absolute;
                        top: 100%;
                        right: 8px;
                        border: 5px solid transparent;
                        border-top-color:#fff;
                    }
                    p{
                        font-size:14px;
                        font-weight:400;
                        color:rgba(0,0,0,0.65);
                        height:22px;
                        line-height:22px;
                        padding: 11px 0;

                    }
                }
                &:hover .tooltip{
                    opacity: 1;
                }
                i{
                    font-size: 14px;
                    border-radius: 2px;
                    border: 1px solid rgba(165,173,180,1);
                    color: #A5ADB4;
                }
            }
        }
        .list-group {
            .schedule-item {
                &:nth-of-type(even) {
                    border-top: 1px solid rgba(220, 227, 232, 1);
                    border-bottom: 1px solid rgba(220, 227, 232, 1);
                }
                &:last-of-type {
                    border-bottom: 1px solid rgba(220, 227, 232, 1);
                }
            }
        }
        .list-group-item.add {
            display: flex;
            height: 80px;
            width: 100%;
            text-align: center;
            color: #000;
            border-bottom: 1px solid rgba(220, 227, 232, 1);
            .title {
                width: 240px;
                background: rgba(247, 249, 250, 1);
                border-right: 1px solid rgba(220, 227, 232, 1);
                border-left: 1px solid rgba(220, 227, 232, 1);
                @include ftb;
                justify-content: center;
                i.el-icon-plus{
                    font-size: 24px;
                    color: #1D517E;
                    font-weight: 600;
                }
            }
            .item {
                flex: 1;
                text-align: center;
                border-right: 1px solid rgba(220, 227, 232, 1);
                // border-right: 1px solid red;
                background: rgba(255, 255, 255, 1);
                position: relative;
                .add-res-btn {
                    box-sizing: border-box;
                    // margin-top: 24px;
                    color: #1D517E;
                    height: 100%;
                    @include flex(column, center, center);
                    .add-res-btn-item {
                        cursor: pointer;
                        span {
                            font-size: 18px;
                        }
                        &:last-of-type {
                            margin-top: 10px;
                        }
                    }
                }
                .add-res-form {
                    padding: 20px;
                }
                &.hide {
                    display: none;
                }
                .to-right {
                    position: absolute;
                    right: 0;
                    top: 31px;
                    color: #1d517e;
                    cursor: pointer;
                    font-size: 18px;
                }
                .to-left {
                    position: absolute;
                    left: 0;
                    top: 31px;
                    color: #1d517e;
                    cursor: pointer;
                    font-size: 18px;
                }
                &-edit{
                    &.item-empty:hover{
                        transition: transform 0.2s ease-in;
                        transform: scale(1.01);
                        z-index:2;
                        border:1px solid rgba(237, 183, 189, 1);
                        box-shadow:0px 2px 20px 0px rgba(70,79,85,0.2);
                        border-radius: 2px;
                    }
                }
            }
        }
    }
    .add-schedule-item {
        padding: 5px 0;
    }
    .schedule-footer, .schedule-print-footer {
        padding: 0 30px;
        margin-bottom: 30px;
        .content {
            background: #fff;
            width: 100%;
            box-sizing: border-box;
            border: 1px solid rgba(220, 227, 232, 1);
            border-radius: 0 0 6px 6px;
            overflow: hidden;
            border-top: 0;
            @include flex(row, flex-start, flex-start);
            position: relative;
            &::after{
                content: '';
                width: 1px;
                height: 100%;
                background-color: rgba(220, 227, 232, 1);
                position: absolute;
                left: 50%;
            }
        }
        .item {
            width: 50%;
            padding: 10px;
            .title {
                width: 100%;
                text-align: left;
                font-size: 14px;
                font-weight: 500;
                color: rgba(63, 69, 73, 1);
                margin-bottom: 14px;
            }
            .text-area {
                white-space: pre-wrap;
                word-break:break-all;
                width: 99%;
                min-height: 50px;
                text-align: left;
                padding: 10px;
                box-sizing: border-box;
                color: #3F4549;
                background:#fafbfc;
                font-size: 12px;
                line-height: 17px;
                background:rgba(250,251,252,1);
                border-radius:4px;
                border:1px solid rgba(231,237,240,1);
                user-select: text;
            }
            .input-area {
                width: 99%;
                text-align: left;
            }
        }
    }
}
</style>

<style lang='scss'>
.schedule-footer{
    .input-area{
        textarea{
            color: #3F4549;
            background:#fafbfc;
            font-size: 12px;
            line-height: 17px;
            padding: 10px;
        }
    }
}
</style>


<style lang="scss" scoped>
.container.print {
    padding: 32px 40px 0;
    width: 1440px;
    height: 1018px;
    box-sizing:border-box;
    overflow: hidden;
    // display: flex;
    // justify-content: center;
    // flex-direction: column;
    // align-items: center;
    .schedule-print-header {
        position: relative;
        .logo {
            position: absolute;
            top: 9px;
            left: 0;
            img {
                height: 31px;
            }
        }
        .info {
            color: #000000;
            .title {
                height: 37px;
                font-size: 26px;
                font-weight: 900;
                // font-family: STSongti-SC-Black, STSongti-SC;
                font-family: $SongTi;
                line-height: 37px;
            }
            .sub {
                height: 20px;
                margin-top: 6px;
                span {
                    // font-family: STSongti-SC-Regular, STSongti-SC;
                    font-family: $SongTi;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                }
                span + span {
                    margin-left: 30px;
                }
            }
        }
    }
    .schedule-print-body {
        padding: 0;
        margin-top: 9px;
        .schedule-title {
            height: 30px;
            line-height: 30px;
            border-radius: 0;
            background: transparent;
            border: $print-border;
            .title {
                // font-family: STSongti-SC-Bold, STSongti-SC;
                font-family: $SongTi;
                font-weight: bold;
                width: 150px;
            }
            .schedule-title-item {
                border-left: $print-border;
                // font-family: STSongti-SC-Bold, STSongti-SC;
                font-family: $SongTi;
                font-weight: bold;
            }
        }
        .list-group {
            .schedule-item {
                &:nth-of-type(even) {
                    border-top: $print-border;
                    border-bottom: $print-border;
                }
                &:last-of-type {
                    border-bottom: $print-border;
                }
            }
        }
    }
    .schedule-print-footer {
        padding: 0;
        margin-bottom: 0;
        .content {
            border: $print-border;
            border-top: 0;
            border-radius: 0;
            &::after{
                background-color: #000;
            }
            .item {
                padding: 9px 24px 10px;
                .title {
                    margin-bottom: 7px;
                    height: 22px;
                    font-size: 16px;
                    // font-family: STSongti-SC-Bold, STSongti-SC;
                    font-family: $SongTi;
                    font-weight: bold;
                    color: #000000;
                    line-height: 22px;
                }
                .text-area {
                    background-color: transparent;
                    border: 0;
                    padding: 0;
                    font-size: 12px;
                    // font-family: STSongti-SC-Regular, STSongti-SC;
                    font-family: $SongTi;
                    font-weight: 400;
                    color: #000000;
                    line-height: 17px;

                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 7;
                    overflow: hidden;
                }
                // &.left {
                //     // border-right: $print-border;
                // }
            }
        }
        .powered {
            padding: 8px 0 5px;
            text-align: right;
            img {
                height: 22px;
            }
        }
    }
}
</style>